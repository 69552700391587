import { browser } from "$app/environment";

export const format_price = (value, fx_code = 'USD') => {
    
    let locale = 'en-CA'; // use US$ instead of $ for ssr 
    if (browser) locale = window?.navigator?.language || 'en-CA';

    /** @type {Intl.NumberFormatOptions} */
    let opts = {
        style: 'currency',
        currency: fx_code,
        currencyDisplay: 'symbol',
        minimumFractionDigits: value % 1 === 0 ? 0 : 2,
    }

    try {
        return new Intl.NumberFormat(locale, opts).format(value);
    } catch (error) {
        // Fallback to a default locale if the provided locale is incorrect
        return new Intl.NumberFormat('en-CA', opts).format(value);
    }
};